




import { Component, Vue } from "vue-property-decorator";

@Component
class CompanyModule extends Vue {}

export default CompanyModule;
